import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_WP_URL || 'https://ecommerce.hnikoloski.com/wp-json',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchExperiences = async () => {
  try {
    const response = await api.get('/portfolio/v1/experiences');
    return response.data;
  } catch (error) {
    console.error('Error fetching experiences:', error);
    throw error;
  }
};

export const fetchProjects = async () => {
  try {
    const response = await api.get('/portfolio/v1/projects');
    console.log('Raw API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const fetchAbout = async () => {
  try {
    const response = await api.get('/portfolio/v1/about');
    // Transform the WordPress response to match the expected structure
    const data = response.data;
    return {
      description: data.description,
      image: {
        url: data.image.url,
        alt: data.image.alt || '',
        width: data.image.width,
        height: data.image.height
      }
    };
  } catch (error) {
    console.error('Error fetching about:', error);
    throw error;
  }
};

export const fetchCV = async () => {
  try {
    const response = await api.get('/portfolio/v1/cv');
    // Transform the WordPress response to match the expected structure
    return {
      url: response.data.cv.url,
      name: response.data.cv.filename,
      size: response.data.cv.filesize,
      mime: response.data.cv.mime_type
    };
  } catch (error) {
    console.error('Error fetching CV:', error);
    throw error;
  }
};